import { AppBar } from '@mui/material';
import { useState } from 'react';
import './App.css';
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
function App() {

  var sessionContainer
  var authEndpoint = process.env.REACT_APP_AUTH_URL;
  const [sessionName, setSessionName] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState('');

  var config = {
    videoSDKJWT: '',
    sessionName: 'test',
    userName: Math.random().toString(36).substring(7),
    sessionPassword: process.env.REACT_APP_SESSION_PASSWORD,
    features: ['preview', 'audio', 'share', 'chat', 'users', 'settings', 'users', 'video'],
    options: {
      init: {
        enforceMultipleVideos: true,

      }, audio: {}, video: {
      }, share: {

      }
    },
    virtualBackground: {
      allowVirtualBackground: true,
      allowVirtualBackgroundUpload: true,
      virtualBackgrounds: ['https://images.example.com/image', 'http://example.com/assets/backgrounds/sample.png']
    }
  };

  const sanitizeString = (str) => {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      "/": '&#x2F;',
    };

    const reg = /[&<>"'/]/ig;

    return str.replace(reg, (match) => map[match]);
  }

  const validEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const _ = emailPattern.test(email);
    return _;
  }


  var role = 0;

  async function getToken() {
    setError('')
    sessionContainer = document.getElementById('sessionContainer')
    if (!userName || !sessionName) {
      alert('Please enter your display name and email address to join the call.')
      return
    }
    if (!validEmail(sessionName)) {
      setError('Please enter a valid email address.')
      return
    }

    config.userName = sanitizeString(userName)
    config.sessionName = sanitizeString(sessionName)

    const requestBody = {
      role: role,
      sessionName: config.sessionName,
      expirationSeconds: 3600,
      userIdentity: config.userName,
      sessionKey: config.sessionPassword,
      geoRegions: ['US'],
      cloudRecordingOption: 0,
      cloudRecordingElection: 0,
      audioCompatibleMode: 1
    }

    const apiKey = process.env.REACT_APP_AUTH_API_KEY;
    const apiSecret = process.env.REACT_APP_AUTH_SECRET;
    const { iv, encryptedApiKey } = await encryptApiKey(apiKey, apiSecret);
    const encodedCredentials = btoa(`${encryptedApiKey}:${iv}`);

    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${encodedCredentials}`
      },
      body: JSON.stringify(requestBody),
      redirect: "follow"
    };

    fetch(authEndpoint, requestOptions).then((response) => {
      return response.json()
    }).then((data) => {
      if (data.signature) {
        document.getElementById('join-flow').style.display = 'none'
        config.videoSDKJWT = data.signature
        joinSession()
      } else {
        setError('An error occurred while trying to join the call. Please try again later.')
      }
    }).catch((error) => {
      setError('An error occurred while trying to join the call. Please try again later.')
    })
  }

  function joinSession() {
    uitoolkit.joinSession(sessionContainer, config)
    uitoolkit.onSessionClosed(sessionClosed)
  }

  var sessionClosed = (() => {
    uitoolkit.closeSession(sessionContainer)
    document.getElementById('join-flow').style.display = 'block'
  })

  return (
    <div className="App">
      <main>
        <div
          id='join-flow'
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '100px' }}>
          <AppBar
            position="fixed"
            color="default"
            elevation={5}
          >
            <img
              src="/header-logo.svg"
              alt="Phyxable Logo"
              style={{ width: '150px', height: 'auto', margin: '10px', cursor: 'pointer', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
            />

          </AppBar>
          <Box
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '100px' }}
            component={'form'}
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField id="userName" label="Your Display Name" value={userName} onChange={(e) => setUserName(e.target.value)} />
            <TextField id="sessionName" label="Your Email Address" value={sessionName} onChange={(e) => setSessionName(e.target.value)}
              type="email" />



            <p style={{ fontSize: '12px', color: 'gray' }}>
              * Entering your name and email address will redirect you to a 1-on-1 video call with your practitioner.
            </p>
            <Button
              style={{
                height: '56px',
                fontFamily: 'futura',
                letterSpacing: '.5px',
                fontSize: '18px',
                fontWeight: '900',
                width: '300px',
                borderRadius: '28px',
                backgroundColor: '#74d173',
                color: '#fff'
              }}
              variant="text" onClick={getToken}
            >Join Call</Button>
            <p style={{ fontSize: '12px', color: 'red' }}>{error}</p>
          </Box>
        </div>
        <div
          id='sessionContainer'
        ></div>
      </main>
    </div>
  );
}

export default App;

const encryptApiKey = async (apiKey, secretKey) => {
  const enc = new TextEncoder();
  const keyBuffer = enc.encode(secretKey);
  const apiKeyBuffer = enc.encode(apiKey);

  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    keyBuffer,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt']
  );

  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    cryptoKey,
    apiKeyBuffer
  );
  return {
    iv: btoa(String.fromCharCode(...iv)),
    encryptedApiKey: btoa(String.fromCharCode(...new Uint8Array(encryptedData))),
  };
}